import { IAuthPartnerInfo, IAuthUserAccess, IJwtInfo } from "./auth.interface";
import { defineStore } from "pinia";

const useAuthStore = defineStore("auth", {
  state: () => ({
    userAccess: {} as IAuthUserAccess,
    partnerInfo: {},
    userPolicies: {},
    jwtInfo: {}
  }),
  actions: {
    setAuthState(
      userAccess: IAuthUserAccess,
      partnerInfo: IAuthPartnerInfo,
      userPolicies: any,
      jwtInfo: IJwtInfo
    ): void {
      this.userAccess = userAccess;
      this.partnerInfo = partnerInfo;
      this.userPolicies = userPolicies;
      this.jwtInfo = jwtInfo;
    },
    resetAuthState(): void {
      this.userAccess = {} as IAuthUserAccess;
      this.partnerInfo = {};
      this.userPolicies = {};
      this.jwtInfo = {};
    },
    setJwtInfo(jwtInfo: IJwtInfo): void {
      this.jwtInfo = jwtInfo;
    },
    changePartnerInfo(
      userAccess: IAuthUserAccess,
      partnerInfo: IAuthPartnerInfo,
      userPolicies: any
    ): void {
      this.userAccess = userAccess;
      this.partnerInfo = partnerInfo;
      this.userPolicies = userPolicies;
    }
  },
  getters: {
    getAuthState: (state) => state
  }
});

export default useAuthStore;
