
  import { defineComponent } from "@nuxtjs/composition-api";

  export default defineComponent({
    name: "Dialog",

    props: {
      value: {
        type: Boolean,
        default: false
      }
    },
    
    emits: ["outsideClick", "ok"],

    setup(props, { emit }) {
      const outsideClick = () => {
        emit("outsideClick");
      };

      return {
        outsideClick
      };
    }
  });
