import { render, staticRenderFns } from "./DialogInfo.vue?vue&type=template&id=2d77610e&scoped=true&lang=pug"
import script from "./DialogInfo.vue?vue&type=script&lang=ts"
export * from "./DialogInfo.vue?vue&type=script&lang=ts"
import style0 from "./DialogInfo.vue?vue&type=style&index=0&id=2d77610e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d77610e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LsButton: require('/workspace/portal/hosting/components/_base/Button/Button.vue').default,LsButtonWrapper: require('/workspace/portal/hosting/components/_base/Button/ButtonWrapper/ButtonWrapper.vue').default})
